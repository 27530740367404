export class OrdersRequest {
    id: number;
    initialDate: number;
    finalDate: number;
    status: string;
    page: number;
    per_page: number;
    constructor() {
        this.status = '';
    }
}
