import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestHelper } from '../helpers/requestHelper';
import { environment } from 'src/environments/environment';
import { HttpHeaders , HttpParams} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  helper: RequestHelper = new RequestHelper();

  constructor(private http: HttpClient ) {

  }


  async get(url: any, params: Object= {}): Promise<any> {

    const fullUrl = `${environment.urlApi}${url}`;

    this.helper.loadToken();

    const headers = {
      headers: new HttpHeaders({'Authorization': this.helper.authToken }),
      params: new HttpParams(params)
    };

    return await this.http.get<any>(fullUrl, headers).toPromise();
    // return await this.http.get<any>(fullUrl).toPromise();

  }

  async post(url: any, form: Object= {}, params: Object= {}): Promise<any> {

    const fullUrl = `${environment.urlApi}${url}`;

    this.helper.loadToken();

    const headers = {
      headers: new HttpHeaders({'Authorization': this.helper.authToken }),
      params: new HttpParams(params)
    };

    return await this.http.post<any>(fullUrl, form,  headers).toPromise();
    // return await this.http.get<any>(fullUrl).toPromise();

  }


}
