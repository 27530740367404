import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageModel } from '../models/storage.model';
import { RequestHelper } from '../helpers/requestHelper';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StockService {

  requestHelper: RequestHelper = new RequestHelper();

  constructor(private http: HttpClient) {

  }

  async updateBlockStock(data) : Promise<any> {
    let url = `${environment.urlApi}V2/catalogs/update-block-store-stock`;
    return await this.http.post<any>(url, data, this.requestHelper.createOptions()).toPromise();
  }

  async updatePromotionalPrice(data) : Promise<any> {
    let url = `${environment.urlApi}V2/catalogs/update-store-promotional-price`;
    return await this.http.post<any>(url, data, this.requestHelper.createOptions()).toPromise();
  }

  async getBySku(sku:string) : Promise<any> {
    let url = `${environment.urlApi}V2/catalogs/store-stock-info/${sku}`;
    let response = await this.http.get<any>(url, this.requestHelper.createOptions()).toPromise();
    return response;
  }

  async all(text:string, page:number, per_page:number, stock:string) : Promise<any> {
    let url = `${environment.urlApi}V2/catalogs/search?text=${text}&page=${page}&per_page=${per_page}&stock=${stock}`;
    let response = await this.http.get<any>(url, this.requestHelper.createOptions()).toPromise();
    return response;
  }

  async advanced(sku:string) : Promise<any>{

    let url = `${environment.urlApi}storages/advanced?sku=${sku}`;

    return await this.http.get<any>(url, this.requestHelper.createOptions()).toPromise();

  }

  async advancedRecovery(data:any) : Promise<any>{

    let url = `${environment.urlApi}storages/advanced/recovery`;

    return await this.http.post<any>(url, data, this.requestHelper.createOptions()).toPromise();

  }

}
